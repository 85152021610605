export default class NouisliderManager {
  constructor (sliderDiv, formatter = null, changedCallback) {
    this.sliderDiv = sliderDiv
    this.formatter = formatter
    this.changedCallback = changedCallback
  }

  reset(){
    let values;
    if (this.twoValuesSlider) {
     values = [this.min, this.max]
    } else {
      values = this.max;
    }
    this.setValues(values);
  }

  setMin(value){
    const current_max = parseInt(this.noUiSliderInstance.get()[1].replace(/ /g,''));
    this.setValues([value, current_max])
  }
  setMax(value){
    const current_min = this.noUiSliderInstance.get()[0];
    this.setValues([current_min, value])
  }
  setValues(values){
    this.noUiSliderInstance.set(values)
  }
  initialize (dataset, inputFields, tooltipTextMethod) {

    this.min = parseInt(dataset.defaultMin_value)
    this.max = parseInt(dataset.defaultMax_value)
    let start;
    if ("current_value" in dataset) {
      start = parseInt(dataset.current_value || this.max)
      this.twoValuesSlider = false
    } else {
      start = [
        parseInt(dataset.min_value || this.min),
        parseInt(dataset.max_value || this.max)
      ]
      this.twoValuesSlider = true
    }

    let params = {
      start,
      range: { 'min': [this.min], 'max': [this.max] },
    }
    if (this.twoValuesSlider) {
      params.connect = [false, true, false]
    }
    if (this.formatter != null) {
      params.format = this.formatter()
    }
    noUiSlider.create(this.sliderDiv, params)
    this.noUiSliderInstance = this.sliderDiv.noUiSlider;
    this.update(inputFields, tooltipTextMethod)
  }

  update (inputs, tooltipTextMethod) {
    this.noUiSliderInstance.on('update', (values) => this.updateCallback(values, inputs, tooltipTextMethod))
    this.end(inputs)
  }

  end (inputs) {
    this.noUiSliderInstance.on('end', (values) => this.endCallback(values, inputs))
  }

  updateCallback (values, inputs, tooltipTextMethod) {
    let infValue = this.parseValue(values[0])

    inputs[0].value = infValue
    if (values[1]) {
      inputs[1].value = this.parseValue(values[1])
    }

    // Targets ".js-filter-tooltip"
    const filterTooltip = this.sliderDiv.parentElement.previousSibling
    filterTooltip.innerHTML = tooltipTextMethod(infValue, values[1])
  }

  parseValue (input) {
    return parseInt(input.replace(/[\s|km]/g, ''), 0)
  }

  endCallback (values, inputs) {
    inputs[0].value = this.parseValue(values[0])
    if (this.twoValuesSlider) {
      inputs[1].value = this.parseValue(values[1])
    }
    this.changedCallback()

  }
}