export default class CalculateActiveSearchFilters {
  bodyStylesEnabled () {
    const inputElements = document.getElementsByName('body_styles[]')
    for (let i = 1; i < inputElements.length; i++) {
      if (inputElements[i].type === 'checkbox') {
        if (inputElements[i].checked) { return true }
      }
    }
    return false
  }

  motorEnabled () {
    const inputElements = document.getElementsByName('motor')
    for (let i = 1; i < inputElements.length; i++) {
      if (inputElements[i].type === 'radio') {
        if (inputElements[i].checked) { return true }
      }
    }
    return false
  }

  transmissionEnabled () {
    const inputElements = document.getElementsByName('transmission')
    for (let i = 1; i < inputElements.length; i++) {
      if (inputElements[i].type === 'radio') {
        if (inputElements[i].checked) { return true }
      }
    }
    return false
  }

  brandEnabled () {
    const inputElement = document.getElementsByName('force_brand_model')[0]
    if(!inputElement){
      return false
    }
    return inputElement.value !== ''
  }

  multiselectEnabled () {
    const inputElements = document.getElementsByName('brand_name[]')
    for (let i = 1; i < inputElements.length; i++) {
      if (inputElements[i].type === 'checkbox') {
        if (inputElements[i].checked) { return true }
      }
    }
    return false
  }

  deliveryEnabled () {
    const inputElement = document.getElementsByName('delivery')[0]
    return inputElement.checked
  }

  superhostEnabled () {
    const inputElement = document.getElementsByName('superhost')[0]
    return inputElement.checked
  }

  instantEnabled () {
    const inputElement = document.getElementsByName('instant')[0]
    return inputElement.checked
  }

  driverEnabled () {
    const inputElement = document.getElementsByName('with_driver')[0]
    return inputElement.checked
  }

  yearEnabled () {
    const yearSliderDiv = document.getElementById('js-search_year_slider')

    return !(parseInt(yearSliderDiv.dataset.defaultMin_value) === parseInt(yearSliderDiv.noUiSlider.get()[0]) &&
            parseInt(yearSliderDiv.dataset.defaultMax_value) === parseInt(yearSliderDiv.noUiSlider.get()[1]))
  }

  seatsEnabled () {
    const seatsSliderDiv = document.getElementById('js-search_seats_slider')

    return !(parseInt(seatsSliderDiv.dataset.defaultMin_value) === parseInt(seatsSliderDiv.noUiSlider.get()[0]) &&
        parseInt(seatsSliderDiv.dataset.defaultMax_value) === parseInt(seatsSliderDiv.noUiSlider.get()[1]))
  }

  driverAgeEnabled () {
    const ageSliderDiv = document.getElementById('js-search_driver_min_age_slider')

    return parseInt(ageSliderDiv.noUiSlider.get().toString()) < parseInt(ageSliderDiv.dataset.defaultMax_value)
  }

  licenceMinEnabled () {
    const ageSliderDiv = document.getElementById('js-search_driver_min_licence_slider')

    return parseInt(ageSliderDiv.noUiSlider.get().toString()) < parseInt(ageSliderDiv.dataset.defaultMax_value)
  }

  priceEnabled () {
    const priceSliderDiv = document.getElementById('js-search_daily_price_slider')

    return !(parseInt(priceSliderDiv.dataset.defaultMin_value) === parseInt(priceSliderDiv.noUiSlider.get(true)[0]) &&
      parseInt(priceSliderDiv.dataset.defaultMax_value) === parseInt(priceSliderDiv.noUiSlider.get(true)[1]))
  }

  process () {
    const filters = [this.motorEnabled, this.transmissionEnabled, this.bodyStylesEnabled, this.brandEnabled,
      this.licenceMinEnabled, this.instantEnabled, this.superhostEnabled,
      this.deliveryEnabled, this.driverEnabled, this.driverAgeEnabled,
      this.yearEnabled, this.seatsEnabled];

    return this.processFilters(filters);
  }

  processFeatures () {
    const filters = [this.motorEnabled, this.transmissionEnabled, this.bodyStylesEnabled, this.brandEnabled,
      this.yearEnabled, this.seatsEnabled];
    return this.processFilters(filters)
  }

  processOptions () {
    const filters = [this.deliveryEnabled, this.instantEnabled, this.driverEnabled, this.superhostEnabled,
      this.driverAgeEnabled, this.superhostEnabled, this.licenceMinEnabled];
    return this.processFilters(filters)
  }

  processFilters(filters){
    return filters.reduce((count, filter) => filter() ? count + 1 : count, 0)
  }

  processPrice () {
    return this.processFilters([this.priceEnabled]);
  }
}
