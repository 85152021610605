import ReadSmore from 'read-smore'

function readMore (arrow_path, alt) {
  const readMores = document.querySelectorAll('.js-read-smore')
  const arrowDown = arrowIcon(arrow_path, alt)
  const arrowUp = arrowIcon(arrow_path, alt)
  arrowUp.classList.add("rotate")

  const options = {
    isInline: false,
    moreText: `Voir plus ${arrowDown.outerHTML}`,
    lessText: `Voir moins ${arrowUp.outerHTML}`,
    wordsCount: 60
  }

  const RMs = ReadSmore(readMores, options)
  RMs.init()
}

function arrowIcon(arrow_path, alt) {
  const arrowIcon = document.createElement('img')
  arrowIcon.setAttribute('src', arrow_path)
  arrowIcon.setAttribute('alt', alt)
  arrowIcon.className = 'car-detail-arrow'

  return arrowIcon
}


window.readMore = readMore