import { Modal } from 'bootstrap'
import CustomEvents from "../customEvents";

export default class SearchButtonManager {
    constructor(button) {
        this.button = button
        this.buttonText = button.querySelector(".js-filter-button-text")
        this.buttonImg = button.querySelector(".js-filter-button-img")
        this.buttonContent(button)
        document.addEventListener(CustomEvents.EVENTS_KEY['search-form-submitted'], () => this.buttonContent())
    }

    buttonContent() {
        const buttonId = this.button.id
        const newValues = this.newValues(buttonId)
        const defaultValues = this.defaultFilterValues(buttonId)
        const isDefaultValue = this.compareValues(defaultValues, newValues)
        let buttonText = "";
        if (isDefaultValue) {
            buttonText = this.buttonDefaultText(buttonId)
        } else {
            buttonText = this.buttonModifiedText(buttonId, newValues);
        }
        this.buttonContentRender(isDefaultValue, buttonText)
    }

    buttonContentRender(isDefaultValue, buttonText) {
        if (isDefaultValue) {
            this.button.classList.remove("search-header-bar-filters-button-active")
            this.buttonImg.classList.remove("d-none");
        } else {
            this.button.classList.add("search-header-bar-filters-button-active")
            this.buttonImg.classList.add("d-none");
        }
        this.buttonText.innerHTML = buttonText
    }

    compareValues(defaultValues, newValues) {
        if (newValues.length > 1 && defaultValues.length > 1) {
            return newValues[0] === defaultValues[0] && newValues[1] === defaultValues[1]
        } else {
            return newValues[0] === defaultValues[0]
        }
    }

    defaultFilterValues(buttonId) {
        if (buttonId === "js-filter-price-button") {
            const priceSlider = document.querySelector("#js-search_daily_price_slider");
            return [parseInt(priceSlider.dataset.defaultMin_value), parseInt(priceSlider.dataset.defaultMax_value)]
        } else if (buttonId === "js-filter-brand-button") {
            return [0]
        }
    }

    newValues(buttonId) {
        if (buttonId === "js-filter-price-button") {
            const minPriceInput = document.querySelector("#js-search_inf_daily_price");
            const maxPriceInput = document.querySelector("#js-search_sup_daily_price");
            return [parseInt(minPriceInput.value), parseInt(maxPriceInput.value)]
        } else if (buttonId === "js-filter-brand-button") {
            const multiselectSelect = document.querySelector("#brand_name")
            const numberOfSelectedInputs = document.querySelectorAll(".js-brand-name:checked")
            return [numberOfSelectedInputs.length]
        }
    }

    buttonDefaultText(buttonId) {
        if (buttonId === "js-filter-price-button") {
            return "Prix par jour"
        } else if (buttonId === "js-filter-brand-button") {
            return "Marque"
        }
    }

    buttonModifiedText(buttonId, newValues) {
        if (buttonId === "js-filter-price-button") {
            return `${newValues[0]}€ - ${newValues[1]}€ / jour`
        } else if (buttonId === "js-filter-brand-button") {
            return `Marque (${newValues})`
        }
    }
}