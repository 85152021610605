import CustomEvents from '../customEvents'
import CalculateActiveSearchFilters from '../calculateActiveSearchFilters'

export default class DesktopfiltersManager {
  constructor () {
    document.addEventListener(CustomEvents.EVENTS_KEY['search-form-reset'],
      () => this.calculateActiveDesktopFilters()
    )
    document.addEventListener(CustomEvents.EVENTS_KEY['filters-changed'],
      () => this.calculateActiveDesktopFilters()
    )
  }

  calculateActiveDesktopFilters () {
    this.calculateActiveFeaturesFilters()
    this.calculateActiveOptionsFilters()
    this.calculateActivePriceFilters()
  }

  calculateActiveFeaturesFilters () {
    const parentDiv = document.querySelector('#filtersDropdownFeatures')
    if (!parentDiv) {
      return
    }
    const div = parentDiv.firstChild

    const service = new CalculateActiveSearchFilters()
    const nb = service.processFeatures()

    if (nb === 0) {
      div.classList.remove('text-bold')
    } else {
      div.className = 'text-bold'
    }

    let text
    text = 'Caractéristique'

    if (nb === 1) {
      text = 'Caractéristique (' + nb + ')'
    } else if (nb > 1) {
      text = 'Caractéristiques (' + nb + ')'
    }

    div.innerHTML = text
  }

  calculateActiveOptionsFilters () {
    const parentDiv = document.querySelector('#filtersDropdownOptions')
    if (!parentDiv) {
      return
    }
    const div = parentDiv.firstChild

    const service = new CalculateActiveSearchFilters()
    const nb = service.processOptions()
    if (nb > 0) {
      if (nb > 1) {
        div.innerHTML = 'Options (' + nb + ')'
      } else {
        div.innerHTML = 'Option (' + nb + ')'
      }
      div.className = 'text-bold'
    } else {
      div.classList.remove('text-bold')
      div.innerHTML = 'Option'
    }
  }

  calculateActivePriceFilters () {
    const parentDiv = document.querySelector('#filtersDropdownPrice')
    if (!parentDiv) {
      return
    }
    const div = parentDiv.firstChild

    const service = new CalculateActiveSearchFilters()
    const nb = service.processPrice()
    if (nb > 0) {
      div.innerHTML = 'Prix par jour (' + nb + ')'
      div.className = 'text-bold'
    } else {
      div.innerHTML = 'Prix par jour'
      div.classList.remove('text-bold')
    }
  }
}