function carMapboxInitialize (data) {
  const section = document.querySelector('#js-car-detail-map')
  if (!section) {
    return
  }

  let map

  const callbackFunction = function () {
    mapboxgl.accessToken = data.token

    map = new mapboxgl.Map({
      container: 'js-car-detail-map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: data.center,
      zoom: 11,
      trackResize: true,
      dragRotate: false,
      touchZoomRotate: true
    })

    map.touchZoomRotate.disableRotation() // disable rotation, leaving pinch zoom in tact
    map.keyboard.disableRotation()
    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }))
  }

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        callbackFunction()
        // Optionally, stop observing the element to improve performance.
        observer.unobserve(entry.target)
      }
    })
  }, {
    rootMargin: '0px', // Trigger as soon as the element comes into view.
    threshold: 0 // Even a pixel of visibility triggers the callback.
  })

  observer.observe(section)

}

window.carMapboxInitialize = carMapboxInitialize