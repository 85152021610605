import CustomEvents from '../customEvents'

const resetSearchFormEvent = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['search-form-reset'])
export default class PrefilterManager {
  INITIAL_VALUE = 'custom'
  constructor (resetCallback) {
    this.resetCallback = resetCallback
    this.prefilters = document.querySelectorAll('.prefilter_input')
    this.handlePrefilters()
    this.defineInitialValue()
    document.addEventListener(CustomEvents.EVENTS_KEY['search-form-changed'], () => this.resetPreFilter())
  }

  defineInitialValue () {
    this.currentValue = this.INITIAL_VALUE
    const selectedPrefilter = Array(this.prefilters).find((pf) => pf.checked)
    if (selectedPrefilter) {
      this.currentValue = selectedPrefilter.value
    }
  }

  handlePrefilters () {

    this.prefilters.forEach(function (checkbox) {
      checkbox.addEventListener('click', (e) => this.clickOnPrefilter(e.srcElement))
    }, this)
  }

  resetPreFilter () {
    document.querySelectorAll('.prefilter_input')[0].checked = true
  }

  clickOnPrefilter (el) {
    const value = el.value
    if (value === this.currentValue && value === this.INITIAL_VALUE) {
      return
    } else if (value === this.currentValue) {
      this.currentValue = this.INITIAL_VALUE
      this.resetCallback()
      return
    } else if (value === this.INITIAL_VALUE && this.currentValue !== this.INITIAL_VALUE) {
      this.currentValue = value
      this.resetCallback()
      return
    }
    this.currentValue = value
    let motor, body_styles, min_year, inf_daily_price, power, max_year, sup_daily_price, min_seats, max_seats
    switch (value) {
      case 'electric' :
        motor = 'electric'
        break
      case 'luxury' :
        inf_daily_price = '250'
        min_year = '2000'
        body_styles = ['berline', 'compacte', 'suv-4x4', 'van', 'break', 'coupe', 'cabriolet']
        break
      case 'sport' :
        min_year = '1996'
        body_styles = ['coupe', 'cabriolet']
        break
      case 'classic' :
        max_year = '1995'
        break
      case 'suv' :
        body_styles = ['suv-4x4']
        break
      case 'cabriolet' :
        min_year = '1995'
        body_styles = ['cabriolet']
        break
      case 'family' :
        min_seats = '5'
        max_seats = '7'
        min_year = '1995'
        body_styles = ['berline', 'suv-4x4', 'van', 'break']
        break
      case 'standard' :
        min_year = '1995'
        sup_daily_price = '71'
        body_styles = ['berline', 'suv-4x4', 'van', 'break', 'compacte']
        break
    }

    const filters = { motor, max_year, min_year, sup_daily_price, body_styles, inf_daily_price, min_seats, max_seats }

    const payload = { detail: { filters } }
    const event = CustomEvents.getEvent(CustomEvents.EVENTS_KEY['prefilter-changed'], payload)
    document.dispatchEvent(event)

  }

}