import SearchFormAction from './searchFormAction'

const search_form_action = new SearchFormAction()

export default class SearchFormRequest {
  combineFormsData (form, formId) {
    let body = new FormData(form)
    let other_form
    if (formId === '#home-search') {
      other_form = '#js-filter_search_panel form'
    } else {
      other_form = '#home-search'
    }

    const other_form_element = document.querySelector(other_form)
    const other_form_data = new FormData(other_form_element)
    for (const pair of other_form_data.entries()) {
      body.append(pair[0], pair[1])
    }

    return body
  }

  submitFilterSearch (formId, callback) {
    const form = document.querySelector(formId)
    const message = 'Recherche des voitures en cours...'

    search_form_action.launchLoader(message)

    const body = this.combineFormsData(form, formId)


    const refresh_page = function(response) {
      Promise.resolve(response)
        .then(r => r.text())
              .then(html => {
                Turbo.renderStreamMessage(html)
              }).catch(e => {
                console.error(e)
              })
              .then(() => {
                callback()
                search_form_action.closeLoader()
              })

    }

    fetch(form.getAttribute('action'), {
      method: 'POST',
      body,
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
      }
    })
      .then(response => {
        // HTTP 301 response
        // HOW CAN I FOLLOW THE HTTP REDIRECT RESPONSE?
        if (response.redirected && window.location.pathname !== new URL(response.url).pathname) {
          window.location.href = response.url
          return
        }

        refresh_page(response)
      })


  }

  submitFilterSearchAndGetCount (formId, url, callback) {
    const form = document.querySelector(formId)
    let body = this.combineFormsData(form, formId)

    fetch(url, {
      method: 'POST',
      body,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(r => r.text())
      .then((response) => {
        response = JSON.parse(response)
        let cars_count = 0
        if (response.cars_count) {
          cars_count = response.cars_count
        }
        let new_value = 'Voir les ' + cars_count + ' résultats'
        callback(new_value)
      })
  }
}